.login-container {
  .login-center{
    justify-content: center;
  }

  .login-card {
    overflow: hidden;
    border-radius: .35rem;
  }

  .login-bg {
    background: url('./hikaru.jpeg');
    background-position: center;
    background-size: cover;
  }
}