.Review-Container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  top: 56px;

  .Review-Row {
    height: 100%;
    .ToolBar-Col {
      align-items: stretch;
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      height: 100%;
    }
    .Review-Col {
      align-items: stretch;
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      height: 100%;

      .Action-Bar {
        align-items: center;
        display: flex;
        justify-content: center;
        min-height: 2rem;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        .controls {
          display: flex;
          flex-basis: 30rem;
          flex-grow: 1;
          flex-shrink: 1;
          .move-control {
            cursor: pointer;
            flex-grow: 1;
            flex-shrink: 0;
            text-align: center;
          }
        }
        .move-number {
          flex-basis: 1rem;
          text-align: right;
        }
        .move-number, .icons {
          flex-grow: 1;
          flex-shrink: 0;
          white-space: nowrap;
        }
      }
    }
  }

  .Move-Tree-Container {
    display: inline-block;
    background-color: gray;
    height: 150px;
    width: 100%;
    resize: vertical;
    overflow: scroll;
    border-radius: .2em;
    box-shadow: 0 2px 5px rgba(20,20,20,.71);
  }

  .players {
    align-items: stretch;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: space-around;
    min-height: 2.7rem;
    width: 100%;
    padding: .1rem;
    .player-container {
      border-radius: .3em;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      margin-top: .2em;
      padding: .2em;
      position: relative;
      text-align: center;
      width: 49%;
      &.black {
        background: linear-gradient(to bottom, #7d7d7d 0%, #333 50%, #0c0c0c 100%);
        box-shadow: 0 2px 5px rgba(20,20,20,.71);
        .score-container {
          color: #eee;
        }
      }
      &.white {
        background: linear-gradient(to bottom, #cccccc 0%, #eeeeee 30%, #ffffff 100%);
        box-shadow: 0 2px 5px rgba(50,50,50,.51);
      }
      .player-icon-clock-row {
        align-items: stretch;
        display: flex;
        width: 100%;
        .player-icon-container {
          align-items: center;
          background-repeat: no-repeat;
          background-size: contain;
          display: inline-flex;
          flex-basis: 2rem;
          flex-direction: row;
          flex-grow: 0;
          flex-shrink: 0;
          justify-content: center;
          min-height: 2rem;
          min-width: 2rem;
          .player-flag {
            display: inline-block;
            left: 3rem;
            position: absolute;
            top: 3rem;
          }
        }
      }
      .player-name-container {
        overflow: hidden;
        text-align: left;
        &.white .Player {
          color: #888;
        }
        &.black .Player {
          color: #9dc6ff;
        }
        .Player-username {
          white-space: nowrap;
        }
      }
      .score-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        .points, .captures {
          background-image: none;
          font-size: 1.1rem;
          font-weight: 700;
          position: relative;
        }
      }
    }
    .ChatPresenceIndicator.online {
      text-shadow: -1px -1px 0 #afa, 1px -1px 0 #8c8, -1px 1px 0 #8c8, 1px 1px 0 #6a6;
    }
  }

  .Game-Analyze-Button-Bar {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    img {
      height: 1rem;
      width: 1rem;
    }
    .btn-toolbar {
      margin: 2px;
      .btn-light, input[type="color"] {
        background-color: #ddd;
        border-color: #ddd;
        height: 35px;
      }
      input[type="color"] {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .Chat-Container {
    display: flex;
    flex-basis: 100vh;
    flex-direction: column;
    min-height: 250px;

    .card {
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      max-height: 100%;
      position: relative;
      width: 100%;

      .Chat-Items {
        max-height: calc(100% - 42px);
        overflow-y: scroll;
        .Time {
          font-weight: lighter;
        }
        .LineText {
          display: flex;
          align-items: center;
          align-content: stretch;

          .left, .right {
            flex: 1;
            border-bottom: 1px solid #888;
            border-color: #d2d2d2;
            margin-top: 0.25em;
          }
          .left {
              margin-right: 0.5rem;
          }
          .right {
              margin-left: 0.5rem;
          }
          .contents {
            font-weight: lighter;
            font-size: 0.75em;
            cursor: pointer;
          }
        }
        .variation {
          color: #0af;
          cursor: pointer;
        }
        .position {
          color: #0af;
          cursor: crosshair;
        }
      }

      .Chat-Input {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}

@media screen and (orientation:landscape) {
  .Review-Container {
    .Review-Col, .ToolBar-Col {
      overflow: scroll;
    }
  }
}

@media screen and (orientation:portrait) {
  & {
    .Review-Container {
      position: static!important;
      .Chat-Container {
        flex-basis: 250px;
        flex-grow: 1;
        height: 250px;
      }
    }
  }
}
