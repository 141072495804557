.Goban-Container {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  position: relative;

  .Goban {
    left: 0;
    position: absolute;
    top: 0;
    background-color: #DCB35C;
    display: inline-block;
    box-shadow: 0 4px 8px rgba(128,128,128,.71);

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;


    .GobanMessage {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      font-size: large;
      text-align: center;
    }

    .PenLayer {
      position: absolute;
      left: 0;
      right: 0;
    }

    .StoneLayer {
      position: absolute;
      left: 0;
      right: 0;
    }

    .ShadowLayer {
      position: absolute;
      left: 0;
      right: 0;
    }

    .GobanMessage table {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .GobanMessage table td {
      /* background-color: #f00; */
      background-image: none;
    }

    .GobanMessage table td div {
      border-radius: 3px;
      box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.26);
      color: #FF5500;
      background-color: #262626;
      padding: .3em;
      margin: .4em;
    }

    .hidden {
      display: none;
    }


    &.borderless {
      width: 100%;
      text-align: center;
      margin: 0;
      border-radius: 0;
    }

    &.small {
      font-size: small;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.26);

      canvas {
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.16);
      }
    }
  }
}