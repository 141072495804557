.MiniGoban-Container {
  background-image: url(../../libs/goban/assets/img/kaya.jpg);
  .Goban {
    position: relative;
    .StoneLayer {
      position: absolute;
      top: 0;
      left: 0;
    }

    .GobanMessage {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      font-size: large;
      text-align: center;
    }

    .GobanMessage table {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .GobanMessage table td {
      /* background-color: #f00; */
      background-image: none;
    }

    .GobanMessage table td div {
      border-radius: 3px;
      box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.26);
      color: #FF5500;
      background-color: #262626;
      padding: .3em;
      margin: .4em;
    }
  }
}
