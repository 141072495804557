.More-DropDown {
  .dropdown-toggle::after {
    display: none; 
  }
}
.Post-Card {
  .Post-Header {
    .Avatar {
      width: 50px;
      cursor: pointer;
    }
    .UserLink {
      color: black;
    }
  }
  .Post-Content {
    background-image: url(../../assets/img/background.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    &[href] {
      cursor: pointer;
    }
    .Stone {
      width: 20px;
      height: 20px;
    }
    .Big-Stone {
      width: 40px;
      height: 40px;
      border-width: 3px!important;
    }
    .Star {
      font-size: 1.75em;
      &.text-secondary {
        color: #aaa!important;
      }
    }
    .Puzzle-Reset-Button {
      right: 0;
      bottom: 0;
    }
  }
  .Puzzle-Members {
    .Avatar {
      width: 30px;
      cursor: pointer;
    }
    .Username a {
      color: black;
    }
  }
  .Review-Signal {
    top: 20px;
    right: 10px;
    border-width: 4px!important;
    transform: rotate(20deg);
    box-shadow: 1px 1px 4px black;
    text-shadow: 1px 1px 4px black;
  }
  .Create-Upload-Kifu {
    border-radius: 20px;
  }
}

.User-Info-Container {
  .User-Info {
    position: relative;
    .Background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 50%;
      z-index: 0;
    }
    .Avatar-Container {
      position: relative;
      .Avatar {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }
}

.Top-Reviewers {
  .Avatar {
    cursor: pointer;
    width: 50px;
  }
  .UserLink {
    color: black;
  }
}

.Loading-Container {
  z-index: 100;
  left: 50%;
  top: -20px;
  & > div {
    left: -50%;
    position: relative;
  }
}

.Loading-More-Container {
  z-index: 100;
  left: 50%;
  bottom: -30px;
  & > div {
    left: -50%;
    position: relative;
  }
}
