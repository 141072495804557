body{
  background-color: #f2f2f2;
}

.dropdown-menu {
  z-index: 1024;
}

.navbar {
  .Nav-Link {
    & > .dropdown-toggle {
      display: flex;
      align-items: center;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.modal-dialog {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  @media(max-width: 768px) {
    min-height: calc(100vh - 20px);
  }
}

.Cursor-Pointer {
  cursor: pointer;
}
.Square-20 {
  width: 20px;
  height: 20px;
}
.Square-30 {
  width: 30px;
  height: 30px;
}
.Square-45 {
  width: 45px;
  height: 45px;
}
.Font-Size-2em {
  font-size: 2em;
}
.Right-10 {
  right: 10px;
}
.Top-10 {
  top: 10px;
}
.Bottom-10 {
  bottom: 10px;
}
.Tab-Transparent {
  .active {
    background-color: #f2f2f2!important;
    border-bottom-color: #f2f2f2!important;
  }
}

.NotFound-Page {
  background-color: #f2f2f2;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  h1 {
    font-size: 180px;
    margin: 0;
    font-weight: 900;
    background: url(./assets/img/bg.jpeg) no-repeat;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: cover;
    background-position: center;
  }
}

@media screen and (max-width: 991.98px) and (orientation:landscape) {
  body {
    padding-top: 56px;
  }
  .navbar-expand-lg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1024;
  }
}

@media screen and (orientation:portrait) {
  .d-portrait-flex {
    display: flex !important;
  }

  .d-portrait-none {
    display: none!important;
  }

  .col-landscape {
    flex: 0 0 auto!important;
    max-width: 100%!important;
  }
}

@media screen and (orientation:landscape) {
  .d-landscape-flex {
    display: flex !important;
  }

  .d-landscape-block {
    display: block !important;
  }

  .d-landscape-none {
    display: none!important;
  }

  .col-portrait {
    flex: 0 0 auto!important;
    max-width: 100%!important;
  }
}
